.tableRow {
  height: 30px;
  vertical-align: bottom;
}

.tableHeadCellGroups {
  width: 10px;
  padding-left: 12px;
}

.tableHeadCellGroupsWithBorder {
  width: 10px;
  padding-left: 12px;
  border-left: 2px solid #ffffff;
}

th.tablePaddingLeftCell {
  padding-left: 24px;
}
