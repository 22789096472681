.tablePaddingLeftCheckbox {
  width: 10px;
  padding-left: 12px;
}

.tableHeadCellGroups {
  width: 10px;
  padding-left: 12px;
  border-right: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}

th.tablePaddingLeftCell {
  padding-left: 24px;
}
