.commentsContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 400px;
  padding: 15px 0;
  overflow-y: scroll;
  border: 1px solid #00000038;
  border-radius: 7px;
}

.commentsContainer::-webkit-scrollbar {
  width: 10px;
}

.commentsContainer::-webkit-scrollbar-thumb {
  background-color: #00000038;
  border-radius: 5px;
}

.commentsContainerStudent {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.commentsStudent {
  width: 350px;
  padding: 20px;
  margin: 15px 30px;
  text-align: end;
  border: 1px solid #00000038;
  border-radius: 7px;
}

.commentsTutor {
  width: 350px;
  padding: 20px;
  margin: 15px 30px;
  border: 1px solid #00000038;
  border-radius: 7px;
}

.description {
  word-wrap: break-word;
}
