.tableRow {
  height: 30px;
  vertical-align: bottom;
}

.tableSecondaryCellGroups {
  width: 10px;
  padding-left: 12px;
}

.tableSecondaryCellGroupsWithBorder {
  width: 10px;
  padding-left: 12px;
  border: 2px solid #ffffff;
}

th.tablePaddingLeftCell {
  padding-left: 24px;
}
